import React, {useEffect, useState, useCallback} from "react";
import {Container, Row, Col} from "react-bootstrap";
import axiosInstance from "../../api/axios";
import {Pagination, Spin, Input, Button, Table, Popover, List} from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import {useParams, Link} from "react-router-dom";
import TableActionsList from "../Layouts/TableActionsList";
import toastErrors from "../../utils/toast-errors";

// import DataTable from "react-data-table-component";
function AllStudents() {
    const params = useParams();
    const [columns, setColumns] = useState([
        {
            title: "Name",
            dataIndex: "",
            render: (row) => (
                <>
                    <Link to={`/student_details/${row.id}`}>
                        <p className="nameStyle">
                            {row.user.first_name} {row.user.middle_name} {row.user.last_name}
                        </p>
                        <span className="numStyle" style={{fontSize: "12px"}}>
              {row.code}
            </span>
                    </Link>
                </>
            )
        },
        {
            title: "Phone",
            dataIndex: "user",
            render: (row) => (
                <span className="numStyle" style={{fontSize: "14px"}}>
          {row.phone}
        </span>
            )
        },
        {
            title: "Mother’s Phone",
            dataIndex: "mother_phone",
            render: (row) => (
                <span className="numStyle" style={{fontSize: "14px"}}>
          {row}
        </span>
            )
        },
        {
            title: "Father’s Phone",
            dataIndex: "father_phone",
            render: (row) => (
                <span className="numStyle" style={{fontSize: "14px"}}>
          {row}
        </span>
            )
        },

        {
            title: "Status",
            dataIndex: "status",
            render: (row) => (
                <div
                    style={{
                        backgroundColor: `${row === "1" ? "#18AB56" : row === "2" ? "#FFDD2B" : "#EB5757"
                        }`
                    }}
                    className="status"
                ></div>
            )
        },
        {
            title: "Grade",
            dataIndex: "edu_lvl",
            render: (row) => (
                <Button
                    style={{
                        backgroundColor: `${row === "1" ? " #B9F5FD" : row === "2" ? "#F9DCBA" : "#FF6A6A"
                        }`,
                        color: `${row === "1" ? " #1ABBB9" : row === "2" ? "#DA9D55" : "#B72222"
                        }`,
                        fontWeight: "600"
                    }}
                    className="eduLevel"
                >
                    {row === "1" ? (
                        <p>
                            1<sup>st</sup> sec
                        </p>
                    ) : row === "2" ? (
                        <p>
                            2<sup>nd</sup> sec
                        </p>
                    ) : (
                        <p>
                            3<sup>rd</sup> sec
                        </p>
                    )}
                </Button>
            )
        },
        {
            title: "Attendance",
            dataIndex: "attendance_place",
            render: (row) =>
                row == "1" ? (
                    <Popover content={row?.title}>
                        <Button
                            style={{
                                border: `solid 2px #f28537`,
                                color: `#FFB37B`
                            }}
                            className="centerBtn"
                        >
                            Center
                        </Button>
                    </Popover>
                ) : (
                    <Button
                        style={{
                            border: `solid 2px #0058dc`,
                            color: `#94BFFF`
                        }}
                        className="centerBtn"
                    >
                        Online
                    </Button>
                )
        },
        {
            title: "Type",
            dataIndex: "azhar",
            render: (row) => (
                <Button
                    style={{
                        border: `${row ? "solid 2px #b467d9" : "solid 2px #89cc88"}`,
                        color: `${row ? "#C88BE6" : "#738F4F"}`
                    }}
                    className="centerBtn"
                >
                    {row ? "Azhar" : "General"}
                </Button>
            )
        }
    ]);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [current, setCurrent] = useState(1);
    const [keyword, setKey] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedRow, setSelectedRow] = useState([]);
    const [filters, setFilters] = useState({});
    const [currentMethod, setCurrentMethod] = useState("keyword");

    const getStudentsByKeyword = async (e) => {
        setCurrentMethod("filter");
        setLoading(true);
        let result = await axiosInstance.get(
            `/search_students?page=${current}&key=${e}`
        );
        setFilters({})
        console.log(result);
        if (result) {
            setData(result.data.data);
            setCount(result.data.count);
            setLoading(false);
        }
    };

    async function onFilterChange([key, value]) {
        // setLoading(true)
        const newFilters = {...filters};
        if (value == null) {
            delete newFilters[key];
        } else {
            newFilters[key] = value;
            console.log(newFilters[key]);
        }

        setFilters(newFilters);
        console.log({key}, {value}, {newFilters});

        if (Object.keys(newFilters) == null)
            await getStudentsByKeyword("")
        else
            await getStudentsByFilters(newFilters);
    }


    async function getStudentsByFilters(newFilters) {
        setCurrentMethod("filter");
        setLoading(true);
        let endpoint = `/search_students_filter?page=${current}&`
        const filtersList = Object.keys(newFilters)

        filtersList.forEach(key => {
            console.log({key})
            endpoint += `${key}=${newFilters[key]}&`
        })
        console.log(endpoint)
        let result = await axiosInstance.get(endpoint);
        console.log({result});
        if (result) {
            console.log("hhehe")
            setData(result.data.data);
            setCount(result.data.count);
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true);
        getStudentsByKeyword("");
    }, [keyword]);

    useEffect(() => {
        setCurrent(1)
    }, [currentMethod])

    useEffect(() => {
        if (currentMethod === "keyword")
            getStudentsByKeyword(keyword);
        else
            getStudentsByFilters(filters)
    }, [current])


    const onPageChange = async (page) => {
        setCurrent(page);
    };
    const search = (e) => {
        setSearchText(e.target.value);
    };
    const sendRequest = useCallback(
        async (e) => {
            if (e.key === "Enter") {
                getStudentsByKeyword(searchText);
            } else {
                console.log(e.key);
            }
        },
        [searchText]
    );

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(
                `selectedRowKeys: ${selectedRowKeys}`,
                "selectedRows: ",
                selectedRows
            );
            setSelectedRow(selectedRowKeys);
        }
    };

    const changeStatus = async (e) => {
        let result = await axiosInstance
            .put(`/update_studnet_status`, {
                ids: selectedRow,
                student: {status: e}
            })
            .catch((err) => {
                console.log(err.response);
            });
        console.log(result);
        setLoading(true);
        if (result) {
            setLoading(false);
            setSelectedRow("");
            getStudentsByKeyword("");
        }
    };

    function decode(response) {
        //get the file
        var octetStreamMime = 'application/octet-stream';

        //get the headers' content disposition
        var cd = response.headers["content-disposition"];

        //get the file name with regex
        var regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var match = regex.exec(cd);

        //is there a fiel name?
        var fileName = match[1] || "myDefaultFileName.csv";

        //replace leading and trailing slashes that C# added to your file name
        fileName = fileName.replace(/\"/g, "");

        //determine the content type from the header or default to octect stream
        var contentType = response.headers["content-type"] || octetStreamMime;

        //finally, download it
        try {
            var blob = new Blob([response.data], {type: contentType});

            //downloading the file depends on the browser
            //IE handles it differently than chrome/webkit
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
                var objectUrl = URL.createObjectURL(blob);
                window.open(objectUrl);
            }
        } catch (exc) {
            console.log("Save Blob method failed with the following exception.");
            console.log(exc);
        }

    }

    async function exportStudents() {
        const res = await axiosInstance.get("students_data_export", {responseType: "blob"}).catch(err => toastErrors(err));
        if (res) {
            const file = window.URL.createObjectURL(new Blob([res.data]));
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = file;
            // the filename you want
            a.download = "students.xlsx";
            // <a href=file download="Invoice.txt" style=display/>
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(file);

        }
    }

    return (
        <div>
            <div className="">
                <Container>
                    <Row>
                        <Col className="py-4" sm={6}>
                            {" "}
                            <span className="title1">Students</span>
                        </Col>
                    </Row>
                    {loading ? (
                        <Spin tip="loading...."/>
                    ) : (
                        <>
                            {" "}
                            <Row>
                                <Col sm="12">
                                    <p className="filterHead">Filter By:</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="10">
                                    <TableActionsList onChange={async (it) => await onFilterChange(it)}
                                                      filters={filters}
                                                      onReset={async () => {
                                                          setFilters({});
                                                          await getStudentsByKeyword("");
                                                      }}
                                    />
                                </Col>
                            </Row>
                            <Row className="my-4">
                                <Col sm="3">
                                    <Input
                                        value={searchText}
                                        onChange={search}
                                        placeholder="Search code , phone"
                                        onKeyDown={(e) => sendRequest(e)}
                                        className="searchStudent"
                                    />
                                </Col>
                                <Col className="text-end" sm="9">
                                    <Button
                                        style={{background: "#3ea2ff", color: "#fff"}}
                                        className="tableBtn me-4"
                                        onClick={exportStudents}
                                        // onClick={() => changeStatus("1")}
                                    >
                                        Export Students as Excel
                                    </Button>
                                    <Button
                                        style={{background: "#3c9cdb", color: "#fff"}}
                                        className="tableBtn me-2"
                                        onClick={() => changeStatus("1")}
                                        disabled={selectedRow.length > 0 ? false : true}
                                    >
                                        Accept Selected
                                    </Button>
                                    <Button
                                        style={{background: "#FF6A6A", color: "#fff"}}
                                        className="tableBtn me-1"
                                        onClick={() => changeStatus("3")}
                                        disabled={selectedRow.length > 0 ? false : true}
                                    >
                                        {" "}
                                        Reject Selected
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{textAlign: "center"}} sm={12}>
                                    <Table
                                        rowSelection={{
                                            ...rowSelection
                                        }}
                                        columns={columns}
                                        dataSource={data}
                                        rowKey="id"
                                        pagination={false}
                                    />
                                </Col>
                            </Row>
                            <Row className="p-4 studentPaination">
                                <Col className="text-center" sm="12">
                                    {data.length !== 0 ? (
                                        <Pagination
                                            onChange={onPageChange}
                                            defaultCurrent={current}
                                            total={count}
                                            current={current}
                                        />
                                    ) : null}
                                </Col>
                            </Row>
                        </>
                    )}
                </Container>
            </div>
        </div>
    );
}

export default ErrorHandler(AllStudents, axiosInstance);
